.settingsList {
  padding-left: 1rem;
}

.settingsIcon {
  margin-right: 1rem;
}

.settingsListItem {
  display: flex;
  justify-content: space-between;
}

.apexSetupDownloaderLink {
  display: flex;
}
