.itemContainer {
  margin-bottom: 15px;
}

.itemContainer:last-of-type {
  margin-bottom: 0;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectButton {
  width: 100%;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
}

.inputRow:nth-of-type(odd) {
  background-color: #f6f7f9;
}

:global(.dark) .inputRow:nth-of-type(odd) {
  background-color: #333;
}

.inputRow:last-child {
  margin-bottom: 0;
}

.inputs {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px;
}

.inputs.grid {
  display: grid;
  justify-items: flex-end;
  column-gap: 10px;
}

.inputs.positions1 {
  grid-template-columns: 1fr;
}

.inputs.positions2,
.inputs.positions4 {
  grid-template-columns: 1fr 1fr;
}

.inputs.positions5 {
  grid-template-areas:
    "first first";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.inputs.positions5 > *:first-child {
  grid-area: first;
}

.inputs .partInput {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 245px;
}

.partInput {
  overflow: hidden;
}

.inputs .booleanInput {
  margin: 0;
  width: 100%;
  text-align: right;
}

.sectionAccordion {
  margin-bottom: 15px;
}

.sectionAccordion:last-child {
  margin-bottom: 0;
}

.accordionHeader {
  /* This property is amazing -- be thankful if you don't have to know why */
  scroll-margin-top: 130px;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 15px;
}

.selectTarget {
  flex: 1;
  overflow: hidden;
}

.fieldTooltip {
  max-width: 200px;
  margin: 0;
}

.tooltipIcon {
  margin-left: 5px;
}

.partSelectButton {
  justify-content: space-between !important;
  height: 25px;
}

.selectDesc {
  margin-bottom: 2px !important;
}

.selectPartNum,
.selectSerialNum {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectPartNum,
.selectSerialNum,
.selectMileage {
  margin-bottom: 0 !important;
  line-height: 1.2;
}

.customDisabled {
  color: #f6f7f9 !important;
}

.childContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.childControls {
  display: flex;
  flex-direction: column;
  padding-right: 2px;
  padding-left: 2px;
}

.childWrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.childToolTip {
  margin-right: 3px;
}

.childLabel {
  margin-right: 5px;
  margin-left: 10px;
  white-space: nowrap;
}
