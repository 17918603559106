.container {
  display: flex;
  gap: 15px;
}

.setupSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
