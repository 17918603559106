:root {
  --gap-dist: 20px;
  --apex-toolbar-height: 30px;
}

.titleDropdown .label {
  display: inline-block;
  margin-right: 10px;
}

.titleDropdown .select {
  display: flex;
  flex-basis: 70%;
}

.bodyContainer {
  display: flex;
  flex-direction: row;
  gap: var(--gap-dist);
  height: calc(100vh - var(--apex-toolbar-height) - var(--toolbar-plus-title))
    /*--toolbar-plus-title set in code*/
}

.chartContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  flex-basis: 0;
  gap: var(--gap-dist);
  margin-top: 30px;
}

.chartRow {
  display: flex;
  flex-direction: row;
  gap: var(--gap-dist);
}

.chart {
  display: flex;
  flex-grow: 1;
  min-height: 300px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  margin-top: 30px;
}

.fieldRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.fieldValue {
  justify-items: flex-end;
  flex-basis: 70%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 98px);
  padding-top: 35px;
}

.fieldGridContainer {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 10px;
}

.fieldGridLabel {
  flex-basis: 100%;
  margin-bottom: 5px;
  text-align: center;
}

.fieldGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.fieldGrid .gridFieldValue {
  flex-basis: 40%;
  flex-grow: 1;
}

.cornerInputsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cornerInputsCol {
  width: 50%;
}
