.setupPaneContainer {
  display: flex;
  flex-direction: column;
  max-width: 30px;
}

.setupSelectRow {
  white-space: nowrap;
  transition: opacity 0.25s ease;
  display: flex;
  flex-direction: row;
  margin-left: 7px;
}

.setupSelectRowHide{
  visibility: collapse;
}

.setupSelectRowDetail {
  width: 99%;
  transition: opacity 0.9s ease;
  overflow: hidden;
}

.setupSelectCheckbox {
  max-width: 25px;
  transition: opacity 0.9s ease;
}

.addSetupButton {
  max-height: 25px;
  max-width: 25px;
}

.bp4-control-indicator {
  background-color: green;
}

.pt-checkbox.custom-checked{
  background-color: #ff0000; /* Red when checked */
}

.colorPopover {
  position: absolute;
  z-index: 2;
}

.colorCover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.colorPopover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.pickerContainer {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
