.container {
  position: fixed;
  top: 30px;
  display: flex;
  background-color: #FFF;
  flex-direction: column;
  justify-content: space-between;
  width: 20rem;
  height: calc(100vh - 30px);
  border-right: 1px solid #666666;
  z-index: 2;
  transition: all 0.5s ease;
}

.container.dark {
  background-color: #383E47;
}

.container.collapsed {
  width: 3.5rem;
}

.navButton {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  padding: 1rem;
  background-color: transparent;
  overflow: hidden;
  transition: 0.5s ease;
}

.highlighted {
  background-color: #EAEAEA;
  border-left: 4px solid #007BFF;
}

.container.dark .highlighted {
  background-color: #404854;
  border-left: 4px solid #007BFF;
}

.navButton:hover {
  background-color: #EAEAEA;
}

.container.dark .navButton:hover {
  background-color: #404854;
}

.navButtonText {
  margin-left: 1rem;
  white-space: nowrap;
  transition: opacity 0.25s ease;
}

.expandButton {
  justify-content: flex-end;
}

.container.collapsed .navButtonText {
  opacity: 0;
}

.container .navButtonText {
  opacity: 1;
}

.navButtonContainer {
  display: flex;
  flex-direction: column;
}

.setupNav {
  display: flex;
  margin-left: 12px;
  box-shadow: none;
  outline: none;
}

.setupNavText {
  align-items: center;
  overflow: hidden;
  display: flex;
  margin-left: 20px;
  white-space: nowrap;
  transition: opacity 0.25s ease;
  max-height: 25px;
}

.setupPane {
  background: #2f343c;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
