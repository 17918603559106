.selectFormGroup {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.tooltipTarget {
  display: inline-block !important;
}

.tooltip {
  max-width: 450px;
}

.tooltipContent code {
  background-color: #232323;
}
.tooltipContent.dark code {
  background-color: #BDBDBD;
}

.addPositionContainer {
  margin-bottom: 15px;
}

.positionsList {
  list-style: none;
  margin-bottom: 0;
}

.positionsList > li {
  margin-bottom: 5px;
}

.addNewSetupFieldBtn {

}

.header {
  display: flex;
  background: #383e47;
  foreground: #232323;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px;
  cursor: default;
}

.header span {
  align-self: center;
  cursor: default;
}

.container {
  transition: height 1s ease-in-out;
}

.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.expanded {
  height: 0px;
  transition: height 1s ease-in-out;
}
