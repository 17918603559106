.container {
    width: 100%;
  }

  .expressionContainer {
    position: relative;
  }
  
  .setupFieldDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2px;
  }
  
  .setupFieldOption {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 100ms ease;
  }
  
  .setupFieldOption:hover {
    background-color: #f0f0f0;
  }
  
  .fieldName {
    font-weight: 600;
    color: #182026;
  }
  
  .fieldLabel {
    color: #5c7080;
    font-size: 0.9em;
    margin-left: 8px;
  }
  
  .container.dark .setupFieldDropdown {
    background: #252a31;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4)
  }
  
  .container.dark .setupFieldOption:hover {
    background-color: #394b59;
  }
  
  .container.dark .fieldName {
    color: #f5f8fa;
  }
  
  .container.dark .fieldLabel {
    color: #bfccd6;
  }