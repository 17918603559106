main {
  background-color: #EAEAEA !important;
  overflow: auto;
}

main.dark {
  background-color: #2C2C2C !important;
  overflow: auto;
}

.content {
  padding: 1rem;
  min-height: calc(100vh - 30px);
}

.setupView {
  padding: 0;
}
