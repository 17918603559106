.suitGridContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.suitGrid {
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-bottom: 10px;
  padding: 5px;
}

.gridLabel {
  display: flex;
  flex: 1;
  align-self: center;
  max-width: 120px;
}

.gridRow {
  display: flex;
  flex: 1;
}

.gridItemPlaceholder {
  display: flex;
  flex: 1;
  min-height: 30px;
}

.gridItem {
  display: flex;
  flex: 1;
  justify-content: right;
}

.gridItemDesigner{
  display: flex;
  flex: 1;
  justify-content: right;
  border: black;
  border-style: dashed;
  border-width: 1px;
}
